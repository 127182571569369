import $ from 'jquery';
import "slick-carousel";

$(document).ready(function(){

    let slider = $('.value');
    let controls = $('#slider-control');
    let pause = $('.icon-pause');
    let play = $('.icon-play');
    let flag = 0;
    let bar = $('#bar');
    let slideDesc = $('.value__content-description');
    let slideBtn = $('.value__content-button');
    const slides = [];

    for(let i = 0; i < slider.length; i++){
        slides.push(slider[i].getAttribute('data-slide'));
    }

    $('[js-values-slider]').slick({
        arrows:false,
        pagination:true,
        dots:true,
        fade: true,
        customPaging: function(slick,index) {
            return '<div class="value__name"><span class="headline">' + slides[index] + '</span></div>';
        },
    });

    //Animation du cercle on progress
    let time = 2;
    let percentTime;
    let isPause;
    let tick;
    let circle = $('#bar');
    let r = circle.attr('r');
    let p = 2 * Math.PI * r;

    controls.click(() => {
        if(!isPause){
            play.css({
                display: 'block',
            });
            pause.css({
                display: 'none'
            });
            isPause = true;
        }
        else{
            play.css({
                display: 'none',
            });
            pause.css({
                display: 'block'
            });
            isPause = false;
        }
    });

    const progress = (value) => {
        let progress = value / 100;
        let dashOffset = p * (1 - progress);

        circle.attr('stroke-dashoffset', dashOffset);
    };

    function startProgressbar() {
        resetProgressbar();
        percentTime = 0;
        isPause = false;
        tick = setInterval(interval, 10);
    }

    function interval() {
        if(!isPause) {
            percentTime += 0.25;
            progress(percentTime);
            if(percentTime >= 100)
            {
                $('[js-values-slider]').slick('slickNext');
                startProgressbar();
            }
        }
    }

    function resetProgressbar() {
        progress(0);
        clearTimeout(tick);
    }

    startProgressbar();

    $('[js-values-slider]').on("beforeChange", function (){
        if(isPause){
            play.css({
                display: 'none',
            });
            pause.css({
                display: 'block'
            });
        }
        startProgressbar();
    });


    //Odometer
    let valuesContainer = $('.numbers-img');
    let flagNumber = false;
    let allOdometers = document.querySelectorAll('.odometer-number');

    function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

    if(isScrolledIntoView(valuesContainer) && !flagNumber){
        for(let i = 0; i < allOdometers.length; i++){
            let odometer = new Odometer({
                el: allOdometers[i],
                format: '( ddd)'
            });
            let number = allOdometers[i].getAttribute('data-value');
            number = number.replace(/\s/g, '');
            if(number.indexOf('M') > -1) {
                setTimeout(() => {
                    allOdometers[i].classList.add('letter');
                }, 2000);
            }
            allOdometers[i].innerHTML = number + 'M';
        }
        flagNumber = true;
    }

    window.addEventListener('scroll', () => {
        if(valuesContainer.hasClass('aos-animate') && !flagNumber || window.matchMedia("(max-width:2400px)").matches && !flagNumber){
            for(let i = 0; i < allOdometers.length; i++){
                let odometer = new Odometer({
                    el: allOdometers[i],
                    format: '( ddd)'
                });
                let number = allOdometers[i].getAttribute('data-value');
                number = number.replace(/\s/g, '');
                if(number.indexOf('M') > -1) {
                    setTimeout(() => {
                        allOdometers[i].classList.add('letter');
                    }, 2000);
                }
                allOdometers[i].innerHTML = number;
            }
            flagNumber = true;
        }
    });



});